import React from "react";
import Tool from "./Tool";

const tools = require('../tools.json')

const Tools = (props) => {
    const carouselItems = tools.map((tool, key) => (
        <Tool key={key} image={tool.image} title={tool.item}/>
    ));

    return (
        <section className="ftco-container tools-container">
            <h1 className="text-center mb-4">Tools we use</h1>

            <section className="owl-carousel tools-slider">
                {carouselItems}
            </section>
        </section>
    )
}

export default Tools;