import React from "react";

const Portfolio = () => {
    return (
        <section className="ftco-section">
            <div className="container">
                <div className="row justify-content-center mb-5 pb-5">
                    <div className="col-md-7 text-center heading-section ftco-animate">
                        <span>Portfolio</span>
                        <h2>Checkout a few of my works</h2>
                    </div>
                </div>
                <div className="row no-gutters">

                    <div className="block-3 d-md-flex ftco-animate" data-scrollax-parent="true">
                        <a href="https://webmerx.com" className="image d-flex justify-content-center align-items-center"
                           style={{backgroundImage: "url('./images/portfolio/webmerx.jpg')"}} data-scrollax=" properties: { translateY: '-30%'}"
                        />
                        <div className="text">
                            <h4 className="subheading">E-Commerce Service Provider</h4>
                            <h2 className="heading">
                                <a href="https://webmerx.com">Webmerx</a>
                            </h2>
                            <h4>Laravel Developer</h4>
                            <p>
                                Webmerx is complete solution for every ecommerce business needs, while working at Sassy Infotech PVT LTD, i played a role of lead developer in webmerx team.
                            </p>
                            <ul>
                                <li><b>Laravel</b></li>
                                <li>Payment gateway integration</li>
                                <li>Customized Algorithms</li>
                                <li>Customizable Themes</li>
                                <li>Powerful Admin Panel</li>
                                <li>Social media integation</li>
                            </ul>
                            <p>
                                <a href="https://webmerx.com">View Project</a>
                            </p>
                        </div>
                    </div>

                    <div className="block-3 d-md-flex ftco-animate" data-scrollax-parent="true">
                        <a href="#" className="image order-2 d-flex justify-content-center align-items-center"
                           style={{backgroundImage: "url('./images/portfolio/the-circle-club.png')"}} data-scrollax=" properties: { translateY: '-30%'}"
                        />
                        <div className="text">
                            <h4 className="subheading">Social Platform</h4>
                            <h2 className="heading">
                                <a href="https://www.thecircleclub.net/">TheCircleClub</a>
                            </h2>
                            <h4>CTO & Founder</h4>
                            <p>
                                The Circle Club is an American review, social media, and social networking service company based in Boston, Massachusetts.
                                We are here to revolutionize the way people connect, in a safe and convenient way. Our goal is to remove barriers and eliminate gatekeeping,
                                to expose each other to new culture and ideas. We don’t want you to write a certain way, to talk a certain way, we want you to, JUST BE YOU.
                            </p>
                            <ul>
                                <li><b>Laravel, Vue Js, Node Js, WebSocket</b></li>
                                <li>Realtime one to one Chat</li>
                                <li>Third party API integration</li>
                                <li>Social media integation</li>
                            </ul>
                            <p>
                                <a href="https://www.thecircleclub.net/">View Project</a>
                            </p>
                        </div>
                    </div>

                    <div className="block-3 d-md-flex ftco-animate" data-scrollax-parent="true">
                        <a href="#" className="image d-flex justify-content-center align-items-center"
                           style={{backgroundImage: "url('./images/work-2.jpg')"}} data-scrollax=" properties: { translateY: '-30%'}"
                        />
                        <div className="text order-1">
                            <h4 className="subheading">School/College Management Software</h4>
                            <h2 className="heading">
                                <a href="#">School & College Management Software</a>
                            </h2>
                            <h4>Lead full stack developer</h4>
                            <p>It is a Web Application to manage students, professors, classes, exams, schedules everything you can think about school & college management software, i was leading a team of 4 developer on this amazing project.</p>
                            <ul>
                                <li><b>Laravel, Node Js, WebSocket, WebRTC</b></li>
                                <li>Realtime Chat</li>
                                <li>One to many video chat via WebRTC and WebSocket.</li>
                                <li>Task Scheduling</li>
                                <li>Real time analytics.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="block-3 d-md-flex ftco-animate" data-scrollax-parent="true">
                        <a href="#" className="image order-2 d-flex justify-content-center align-items-center"
                           style={{backgroundImage: "url('./images/portfolio/cryptocurrency.jpg')"}} data-scrollax=" properties: { translateY: '-30%'}"
                        />
                        <div className="text">
                            <h4 className="subheading">Blockchain Platform</h4>
                            <h2 className="heading">
                                <a href="portfolio-single.html">BuySell Cryptocurrency</a>
                            </h2>
                            <h4>Laravel Developer</h4>
                            <p>
                                It is a blockchain platform where user can buy or sell their cryptocurrencies.
                                This web application also provide realtime price and other analytics data of maximum number of cryptocurrencies.
                                (under development)
                            </p>
                            <ul>
                                <li><b>Laravel</b></li>
                                <li>Tranasaction management</li>
                                <li>Third party api integration like, CryptoCompare, CoinPayments</li>
                            </ul>
                        </div>
                    </div>

                    <div className="block-3 d-md-flex ftco-animate" data-scrollax-parent="true">
                        <a href="#" className="image d-flex justify-content-center align-items-center"
                           style={{backgroundImage: "url('./images/work-4.jpg')"}} data-scrollax=" properties: { translateY: '-30%'}"
                        />
                        <div className="text">
                            <h4 className="subheading">Online Medical Consultancy System</h4>
                            <h2 className="heading">
                                <a href="portfolio-single.html">Telephys</a>
                            </h2>
                            <h4>Full stack developer</h4>
                            <p>
                                Telephys is an Online Doctor Consultation Platform where one can have a live chat with a doctor on Web/App,
                                currently working with a team of telephys as lead web developer.
                            </p>
                            <ul>
                                <li><b>Laravel, Vue Js</b></li>
                                <li>Realtime Chat</li>
                                <li>One to one private video chat.</li>
                                <li>Task scheduling and queue management.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Portfolio;