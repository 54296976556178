import React from "react";

const Header = () => (
    <header>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="colorlib-navbar-brand">
                        <a className="colorlib-logo" href="#">
                            <span className="logo-img" style={{backgroundImage: "url('./images/dhruv-vadodariya.jpg')"}}/>
                            Dhruv Vadodariya
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </header>
)

export default Header;