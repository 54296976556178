import React from "react";

const Tool = (props) => (
    <div className="slider-item" title={props.title}>
        <div className="overlay"/>

        <div className="container">
            <img
                src={props.image}
                alt={props.title}
                title={props.title}
                style={{height: '120px', width: '120px'}}
            />

            <p>{props.title}</p>
        </div>
    </div>
)

export default Tool;

