import React from "react";

const Footer = () => (
    <footer className="ftco-footer ftco-bg-dark ftco-section">
        <div className="container">
            <div className="row mb-5 justify-content-center">
                <div className="col-md-5 text-center">
                    <div className="ftco-footer-widget mb-5">
                        <ul className="ftco-footer-social list-unstyled">
                            <li>
                                <a target="_blank" href="https://github.com/vdhruv">
                                    <span className="icon-github"/>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.linkedin.com/in/dhruv-dvvadodariya/">
                                    <span className="icon-linkedin"/>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://stackoverflow.com/users/10189564/dhruv-patel?tab=profile">
                                    <span className="icon-stack-overflow"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="ftco-footer-widget">
                        <h2 className="mb-3">Have any questions?<br/> Reach out!</h2>
                        <p className="h3 email">
                            <a href="mailto:dhruvvadodariya03@gmail.com">dhruvvadodariya03@gmail.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer;