const useSiteMetadata = {
    siteTitle: 'Dhruv Vadodariya',
    siteTitleAlt: 'Dhruv Vaodariya - A Full Stack Developer',
    siteUrl: 'https://vdhruv.dev',
    siteDescription: 'A Full Stack Developer',
    siteLanguage: 'en',
    siteImage: 'https://vdhruv.dev/images/dhruv-vadodariya.jpg',
    author: 'Dhruv Vadodariya',
}

export default useSiteMetadata
