import React from "react";

const About = () => (
    <section className="ftco-section about-section">
        <div className="container">
            <div className="row d-flex" data-scrollax-parent="true">
                <div className="col-md-4 author-img" style={{backgroundImage: "url('./images/dhruv-vadodariya.jpg')"}} data-scrollax=" properties: { translateY: '-70%'}"/>
                <div className="col-md-2"/>
                <div className="col-md-6 wrap ftco-animate">
                    <div className="about-desc">
                        <h1 className="bold-text" style={{height: 10}}>About</h1>
                        <div className="p-5">
                            <h2 className="mb-5">A Full-Stack Developer</h2>
                            <p>
                                Hello I'm <b>Dhruv</b>, I started programming when I was 17 and haven't stopped since.
                                {/*In my free time, I enjoy reading books, watching movies (I love Christopher Nolan),*/}
                                {/*hanging with friends and family, trying foods from different cultures.*/}
                                {/*<br/>*/}
                                {/*My guilty pleasure...pizza.*/}
                                <br/>
                                I have knowledge and experience in working with latest technologies and frameworks like <b>Nodejs</b>,
                                PHP, <b>Laravel</b>, Python, <b>VueJs</b>, <b>ReactJs</b>, <b>TypeScript</b>, React Native,
                                TailwindCSS, SocketIO, WebSockets, <b>MySql</b>, <b>PostgresSql</b>, <b>Redis</b>, <b>MongoDB</b>,
                                Unit Testing, <b>Apache</b>, <b>Nginx</b>, <b>AWS</b>, Git, <b>Docker</b> etc.
                            </p>
                            <p>
                                <a target="_blank" href="https://docs.google.com/document/d/17aUwrzC5jCr-1d-e6fPuZWhu3XMqvHSf/edit?usp=share_link&ouid=113113801098853434457&rtpof=true&sd=true">
                                    Checkout my resume
                                </a>
                            </p>
                            <ul className="ftco-footer-social list-unstyled mt-4">
                                <li>
                                    <a target="_blank" href="https://github.com/vdhruv">
                                        <span className="icon-github"/>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/in/dhruv-dvvadodariya/">
                                        <span className="icon-linkedin"/>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://stackoverflow.com/users/10189564/dhruv-patel?tab=profile">
                                        <span className="icon-stack-overflow"/>
                                    </a>
                                </li>
                            </ul>
                            <h5>Contact me here!</h5>
                            <p>Email: <a href="mail:dhruvvadodariya03@gmail.com">dhruvvadodariya03@gmail.com</a></p>
                            <p>Phone:
                                <a href="tel:+919638866424">+91-9638866424</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default About;