import * as React from "react"
import {Helmet} from "react-helmet"
import {withPrefix} from "gatsby"
import useSiteMetadata from "../hooks/use-site-metadata";

const SEO = ({title = ``, description = ``, pathname = ``, image = ``, children = null}) => {
    const site = useSiteMetadata;

    const {
        siteTitle,
        siteTitleAlt: defaultTitle,
        siteUrl,
        siteDescription: defaultDescription,
        siteLanguage,
        siteImage: defaultImage,
        author,
    } = site

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: `${siteUrl}${pathname || ``}`,
        image: `${siteUrl}${image || defaultImage}`,
    }
    return (
        <Helmet title={title} defaultTitle={defaultTitle} titleTemplate={`%s | ${siteTitle}`}>
            <html lang={siteLanguage}/>
            <meta name="description" content={seo.description}/>
            <meta name="image" content={seo.image}/>
            <meta property="og:title" content={seo.title}/>
            <meta property="og:url" content={seo.url}/>
            <meta property="og:description" content={seo.description}/>
            <meta property="og:image" content={seo.image}/>
            <meta property="og:type" content="website"/>
            <meta property="og:image:alt" content={seo.description}/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={seo.title}/>
            <meta name="twitter:url" content={seo.url}/>
            <meta name="twitter:description" content={seo.description}/>
            <meta name="twitter:image" content={seo.image}/>
            <meta name="twitter:image:alt" content={seo.description}/>
            <meta name="twitter:creator" content={author}/>
            <link rel="icon" type="image/png" href={withPrefix(`./images/favicon.png`)}/>
            <link rel="icon" type="image/png" sizes="32x32" href={withPrefix(`./images/favicon.png`)}/>
            <link rel="icon" type="image/png" sizes="16x16" href={withPrefix(`./images/favicon.png`)}/>
            <link rel="apple-touch-icon" sizes="180x180" href={withPrefix(`./images/favicon.png`)}/>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
            <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap" rel="stylesheet"/>
            {children}
        </Helmet>
    )
}

export default SEO
