import React from "react"
import SEO from "./seo";

const Layout = ({children, className}) => (
    <React.Fragment>
        <SEO/>

        <div className="KW_progressContainer">
            <div className="KW_progressBar">
            </div>
        </div>

        <div className={className}>{children}</div>
    </React.Fragment>
)

export default Layout;