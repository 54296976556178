const addScript = url => {
    const script = document.createElement("script")
    script.src = url
    script.async = false
    document.body.appendChild(script)
}

export const loadExternalScripts = () => {
    [
        "/js/jquery.min.js",
        "/js/jquery-migrate-3.0.1.min.js",
        "/js/bootstrap.min.js",
        "/js/jquery.easing.1.3.js",
        "/js/jquery.waypoints.min.js",
        "/js/jquery.stellar.min.js",
        "/js/owl.carousel.min.js",
        "/js/aos.js",
        "/js/jquery.animateNumber.min.js",
        "/js/scrollax.min.js",
        "/js/main.js",
    ].forEach(addScript)
}

// import "../static/js/jquery.min.js"
// import "../static/js/jquery-migrate-3.0.1.min.js"
// import "../static/js/bootstrap.min.js"
// import "../static/js/jquery.easing.1.3.js"
// import "../static/js/jquery.waypoints.min.js"
// import "../static/js/jquery.stellar.min.js"
// import "../static/js/owl.carousel.min.js"
// import "../static/js/aos.js"
// import "../static/js/jquery.animateNumber.min.js"
// import "../static/js/scrollax.min.js"
// import "../static/js/main.js"