import * as React from "react"
import {useEffect, useState} from "react"
import Layout from "../components/layout";
import Header from "../components/header";
import Slider from "../components/slider";
import About from "../components/about";
import Services from "../components/services";
import Portfolio from "../components/Portfolio";
import Footer from "../components/footer";
import Loader from "../components/loader";
import Tools from "../components/Tools";
import {loadExternalScripts} from "../hooks/load-external-javascript";

const IndexPage = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadExternalScripts();

        setTimeout(() => setLoading(false), 1000);
    }, [])

    return (
        <Layout className="page">
            <div id="portfolio-page">
                <Header/>

                <Slider/>

                <About/>

                <Services/>

                <Portfolio/>

                <Tools/>

                <Footer/>

                <Loader loading={loading}/>
            </div>
        </Layout>
    )
}

export default IndexPage;