import React from "react";

const Services = () => (
    <section className="ftco-section">
        <div className="container">
            <div className="row justify-content-center mb-5 pb-5">
                <div className="col-md-7 text-center heading-section ftco-animate">
                    <span>What i do</span>
                    <h2>My services</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 d-flex align-self-stretch ftco-animate">
                    <div className="media block-6 services p-3 py-4 d-block text-center">
                        <div className="icon mb-3">
                            <span className="icon-layers"/>
                        </div>
                        <div className="media-body">
                            <h3 className="heading">Custom Web Applications</h3>
                            <h3 className="heading">Realtime Application</h3>
                            <h3 className="heading">E-Commerce Website</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex align-self-stretch ftco-animate">
                    <div className="media block-6 services p-3 py-4 d-block text-center">
                        <div className="icon mb-3">
                            <span className="icon-gears"/>
                        </div>
                        <div className="media-body">
                            <h3 className="heading">SPA Applications</h3>
                            <h3 className="heading">Responsive Design</h3>
                            <h3 className="heading">SEO Friendly</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex align-self-stretch ftco-animate">
                    <div className="media block-6 services p-3 py-4 d-block text-center">
                        <div className="icon mb-3">
                            <span className="icon-code"/>
                        </div>
                        <div className="media-body">
                            <h3 className="heading">HTML / CSS</h3>
                            <h3 className="heading">PHP / Laravel</h3>
                            <h3 className="heading">VueJs / ReactJs</h3>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </section>
)

export default Services;
